import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Header, Segment } from "semantic-ui-react";
import { getNumberOfArtworksSold } from "../../../../../../redux/actions/artist";
import { ResponsiveLine } from "@nivo/line";
import ErrorMessage from "../../../../../../components/ErrorMessage";

const ArtistAuctionRecordsRate = ({ artistId, fetchData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetch = async () => {
    setIsLoading(true);
    try {
      const response = await fetchData(artistId);
      setData(response.payload.data);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!artistId) return;
    fetch();
  }, [artistId]);

  const total = (data || []).reduce((acc, pilot) => acc + pilot.count, 0);

  const chartData = [
    {
      id: "auction records",
      data: data.map((d) => {
        return {
          y: d.count,
          x: d._id,
        };
      }),
    },
  ];

  return (
    <Segment basic compact loading={isLoading}>
      <Header
        textAlign={"center"}
        content={"Number of Artworks Sold"}
        subheader={`Total Sold: ${total}`}
      />
      <p>
        {" "}
        This chart gives an overview of the number of artworks sold by an artist
        in a given year. It can be used as a helpful tool to determine the
        popularity of the artist, and how demand for their works has evolved
        over their lifetime.
      </p>
      <div style={{ height: 400 }}>
        {error && <ErrorMessage error={error} />}
        {chartData.length === 0 && <p>No data</p>}
        {chartData.length > 0 && (
          <ResponsiveLine
            data={chartData}
            margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
            yScale={{
              type: "linear",
              min: "0",
              max: "auto",
              stacked: true,
              reverse: false,
            }}
            tick
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Year",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Artworks Sold",
              legendOffset: -40,
              legendPosition: "middle",
            }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
          />
        )}
      </div>
    </Segment>
  );
};

const mapStateToProps = ({ firebase }) => ({
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: (id) => dispatch(getNumberOfArtworksSold(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArtistAuctionRecordsRate);
