import React, { Children, cloneElement, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Grid, Header, Modal, Segment, Table, Popup, Icon } from "semantic-ui-react";
import { getProductList } from "../redux/actions/product";
import { PRODUCT_PATH } from "../constants/paths";
import { businessBenefits } from "../common";

const UpgradeModal = ({ user, children, fetchAll, products }) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (modalOpen) {
      fetchAll();
    }
  }, [modalOpen]);

  return (
    <>
      {user.account_level === "basic" &&
        Children.map(children, (child) => {
          return cloneElement(
            child,
            {
              onClick: () => setModalOpen(true),
            },
            null,
          );
        })}
      {user.account_level !== "basic" && children}
      <Modal closeIcon open={modalOpen} onClose={() => setModalOpen(false)}>
        <Modal.Header content={"Please upgrade your account"} />
        <Modal.Content>
          {products && products.length > 0 && (
            <Grid stackable>
              <Grid.Row columns={products.length}>
                {products.map((product) => (
                  <Grid.Column key={product.id}>
                    <Segment
                      attached={"top"}
                      style={{ backgroundColor: "black" }}
                    >
                      <Header inverted content={product.name} size={"huge"} />
                    </Segment>
                    <Segment attached>
                      <p>{product.metadata.summary}</p>
                    </Segment>
                    <Segment attached={"bottom"}>
                      <Button
                        as={Link}
                        to={`/${PRODUCT_PATH}/${product.id}`}
                        color={"black"}
                        content={product.metadata.price_text}
                      />
                    </Segment>
                  </Grid.Column>
                ))}
              </Grid.Row>
              <Grid.Row centered columns={1}>
                <Grid.Column textAlign="center">
                  <Button
                      basic
                      size="large"
                      content={"Need a custom plan?"}
                      color={"black"}
                      as={"a"}
                      href="mailto:info@artcollection.io?subject=Business Account Inquiry"
                    />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          )}
        </Modal.Content>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ firebase, products }) => ({
  user: firebase.profile || null,
  products: products.products,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAll: () => dispatch(getProductList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UpgradeModal);
