import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Divider,
  Grid,
  Header,
  Item,
  Loader,
  Segment,
} from "semantic-ui-react";
import { createCheckoutSession } from "../../../../../../redux/actions/billing_portal";

import {
  getProduct,
  getProductPlansList,
} from "../../../../../../redux/actions/product";
import { priceString } from "../../../../../../utils/string";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import { Mixpanel } from "../../../../../../utils/Mixpanel";
import { useParams } from "react-router-dom";
import Layout from "../../../../../../layouts/AuthLayout";

const ProductList = ({
  loading,
  fetchProduct,
  fetchProductPlans,
  checkout,
}) => {
  const { productId } = useParams();

  const [product, setProduct] = useState(null);
  const [plans, setPlans] = useState([]);
  const [plan, setPlan] = useState(null);
  const [isCreatingCheckoutSession, setIsCreatingCheckoutSession] =
    useState(false);
  const [checkoutError, setCheckoutError] = useState(null);

  useEffect(() => {
    if (!productId) return;
    Mixpanel.page("product", { product: productId });
    fetchData();
  }, [productId]);

  const fetchData = async () => {
    try {
      const productData = await fetchProduct(productId);
      setProduct(productData.payload.data);

      const planData = await fetchProductPlans(productId);

      const p = planData.payload.data;
      setPlans(p);

      if (p.length === 2) {
        setPlan(p[1]);
      } else if (p.length === 1) {
        setPlan(p[0]);
      }
    } catch (e) {}
  };

  const benefts = (data) => {
    let keys = Object.keys(data).filter((a) => a.startsWith("benefit_"));
    let b = new Array(keys.length);

    for (var key of keys) {
      let index = Number(key.replace("benefit_", ""));
      let d = JSON.parse(data[key]);
      b[index] = d;
    }
    return b;
  };

  const cost = () => {
    if (!plan) return "$0.00";

    let formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });
    let amount = plan.amount;
    return formatter.format(amount / 100);
  };

  const handleSubmit = async (event) => {
    setIsCreatingCheckoutSession(true);
    try {
      const response = await checkout({
        return_url: window.location.href,
        price_id: plan.id,
      });
      const { url } = response.payload.data;
      window.open(url, "_self");
    } catch (e) {
      setCheckoutError(e);
    }
    setIsCreatingCheckoutSession(false);
  };

  return (
    <Layout title={`${product && product.name} Membership`}>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              size="large"
              content={`Your ${product && product.name} membership includes`}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment>
              <Item.Group>
                {product &&
                  product.metadata &&
                  benefts(product.metadata).map((b) => (
                    <Item key={b.title}>
                      <Item.Image size="tiny" src={b.image_url} />
                      <Item.Content verticalAlign={"middle"}>
                        <Item.Header content={b.title} />
                        <Item.Description content={b.description} />
                      </Item.Content>
                    </Item>
                  ))}
              </Item.Group>
              <Divider />
              <Header
                content={"Payment Plan"}
                subheader={"Please select how you would like to be billed:"}
              />
              {loading && <Loader active inline="centered" />}
              <Card.Group itemsPerRow={2}>
                {(plans || []).map((p) => {
                  const selected = plan && plan.id === p.id;
                  return (
                    <Card
                      key={p.id}
                      raised={plan === p.id}
                      style={{
                        opacity: selected ? 1 : 0.5,
                      }}
                      header={priceString(p.amount / 100, p.currency)}
                      description={p.nickname}
                      onClick={() => setPlan(p)}
                    />
                  );
                })}
              </Card.Group>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment basic>
              <Header size="large" content={"Summary"} />
              <div style={{ height: "2.5em" }}>
                <Header size={"small"} floated={"left"} content={"Package"} />
                <Header
                  size={"small"}
                  floated={"right"}
                  content={product && product.name}
                />
              </div>
              <div style={{ height: "2.5em" }}>
                <Header
                  size={"small"}
                  floated={"left"}
                  content={"Payment Plan"}
                />
                <Header
                  size={"small"}
                  floated={"right"}
                  content={plan && plan.nickname}
                />
              </div>
              <Divider />
              <div style={{ height: "2.5em" }}>
                <Header size={"small"} floated={"left"} content={"Amount"} />
                <Header
                  size={"small"}
                  floated={"right"}
                  content={
                    plan && priceString(plan.amount / 100, plan.currency)
                  }
                />
              </div>
              <div
                style={{ marginTop: "4em", marginBottom: "2em", height: "4em" }}
              >
                <Header
                  size={"large"}
                  floated={"left"}
                  content={"Total Amount"}
                />
                <Header size={"large"} floated={"right"} content={cost()} />
              </div>
              {checkoutError && <ErrorMessage error={checkoutError} />}
              <Button
                color={"black"}
                enabled={plan}
                loading={isCreatingCheckoutSession}
                size={"huge"}
                content={"Make Payment"}
                onClick={handleSubmit}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  plans: state.plans.plans,
});

const mapDispatchToProps = (dispatch) => ({
  checkout: (data) => dispatch(createCheckoutSession(data)),
  fetchProduct: (id) => dispatch(getProduct(id)),
  fetchProductPlans: (id) => dispatch(getProductPlansList(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);
