import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Breadcrumb,
  Button,
  Grid,
  Header,
  Image,
  Segment,
  Transition,
} from "semantic-ui-react";

import {
  getMuseum,
  resetMuseumState,
} from "../../../../../../redux/actions/museum";
import { Mixpanel } from "../../../../../../utils/Mixpanel";
import placeholder from "../../../../../../images/placeholder.png";
import MuseumArtworksList from "../MuseumArtworksList";
import EditMuseumSidePane from "../EditMuseumSidePane";
import { Link, useParams } from "react-router-dom";
import AuthLayout from "../../../../../../layouts/AuthLayout/AuthLayout";
import { DASHBOARD_PATH, MUSEUM_PATH } from "../../../../../../constants/paths";

const MuseumDetails = ({ museum, fetch, reset, user }) => {
  const { museumId } = useParams();

  const [error, setError] = useState(false);

  const fetchData = async () => {
    try {
      await fetch(museumId);
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    fetchData();
    Mixpanel.page("museum", { museum: museumId });
  }, [museumId]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <AuthLayout
      title={museum ? museum.name : "Loading..."}
      description={museum && museum.about && museum.about.slice(0, 100)}
    >
      <Grid stackable>
        {error && (
          <Grid.Row>
            <Grid.Column width={16}>
              <ErrorMessage error={error} />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment basic>
              <Breadcrumb>
                <Breadcrumb.Section
                  as={Link}
                  to={DASHBOARD_PATH}
                  content={"Home"}
                />
                <Breadcrumb.Divider />
                <Breadcrumb.Section
                  as={Link}
                  to={MUSEUM_PATH}
                  content={"Museums"}
                />
                <Breadcrumb.Divider />
                <Breadcrumb.Section active content={museum && museum.name} />
              </Breadcrumb>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            {museum && user && user.roles && user.roles.includes("admin") && (
              <EditMuseumSidePane museum={museum}>
                <Button
                  basic
                  floated={"right"}
                  icon={"edit"}
                  content={"Edit Museum"}
                  labelPosition={"left"}
                />
              </EditMuseumSidePane>
            )}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <Transition.Group
              as={Image}
              animation={"fade left"}
              duration={600}
              visible={museum}
              centered
              src={(museum && museum.image_url) || placeholder}
            />
            <Transition.Group
              basic
              as={Segment}
              animation={"fade up"}
              duration={600}
              visible={museum}
            >
              <div style={{ textAlign: "center", marginTop: "1em" }}>
                {museum && museum.website_url && (
                  <Button
                    basic
                    icon={"globe"}
                    labelPosition={"left"}
                    content={"View Website"}
                    as="a"
                    href={museum.website_url}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                )}
                {museum && museum.email && (
                  <Button
                    basics
                    icon={"mail	"}
                    labelPosition={"left"}
                    content={"Contact Auction House"}
                    as="a"
                    href={museum.email}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                )}
              </div>
            </Transition.Group>
          </Grid.Column>
          <Grid.Column width={8}>
            <Transition.Group
              as={Segment}
              animation={"fade down"}
              duration={600}
              visible={museum}
            >
              {museum && <Header size="huge" content={museum.name} />}
              {museum && <p>{museum.about}</p>}
            </Transition.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <MuseumArtworksList museumId={museumId} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ firebase, museum }) => ({
  user: firebase.profile || null,
  museum: museum.museum,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(getMuseum(id)),
  reset: () => dispatch(resetMuseumState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(MuseumDetails);
