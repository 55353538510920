import React, { useState } from "react";
import { connect } from "react-redux";
import { Grid, Header, Step } from "semantic-ui-react";
import StepOne from "./components/StepOne";
import StepTwo from "./components/StepTwo";
import StepThree from "./components/StepThree";
import AuthLayout from "../../../../layouts/AuthLayout/AuthLayout";

const ImportPage = () => {
  const [file, setFile] = useState(null);
  const [objects, setObjects] = useState([]);
  const [mapping, setMapping] = useState(null);
  const [step, setStep] = useState(1);

  const onStepOneComplete = (f, o) => {
    setFile(f);
    setObjects(o);
    setStep(2);
  };

  const onStepTwoComplete = (o, m) => {
    setMapping(m);
    setStep(3);
  };

  const onBack = (s) => setStep(s);

  return (
    <AuthLayout title={"Import Collection"}>
      <Grid stackable>
        <Grid.Row columns={1}>
          <Grid.Column>
            <Header
              size="huge"
              content="Import Artwork"
              subheader={
                "Please follow the steps below to upload artwork from your collection."
              }
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column
            width={5}
            verticalAlign={"top"}
            style={{
              padding: "2em",
            }}
          >
            <Step.Group ordered fluid vertical>
              <Step
                active={step === 1}
                completed={file !== null}
                title={"Upload File"}
              />

              <Step
                active={step === 2}
                completed={file && step === 3}
                title={"Map Fields"}
              />

              <Step active={step === 3} title={"Import Data"} />
            </Step.Group>
          </Grid.Column>
          <Grid.Column
            width={11}
            style={{
              padding: "2em",
            }}
          >
            {step === 1 && (
              <StepOne file={file} onStepOneComplete={onStepOneComplete} />
            )}

            {step === 2 && (
              <Header
                content={"Map CSV columns"}
                subheader={
                  "For each column in your CSV file, please select the appropriate ArtCollection.io field"
                }
              />
            )}
            {step === 2 && (
              <StepTwo
                file={file}
                objects={objects}
                onStepTwoComplete={onStepTwoComplete}
                onBack={() => onBack(1)}
              />
            )}
            {step === 3 && (
              <StepThree
                file={file}
                objects={objects}
                mapping={mapping}
                onBack={() => onBack(2)}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = (state) => ({
  user: state.me.user,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ImportPage);
