import React from "react";
import { Table } from "semantic-ui-react";
import LocationTile from "../LocationTile";
import Location from "../../../../models/location";

const LocationsTable = ({ locations, handleOrderByChange, orderBy }) => {
  if (!locations || locations.size === 0) return null;

  return (
    <Table sortable striped selectable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell
            width={12}
            content={"Name"}
            sorted={
              orderBy[0] === "name"
                ? orderBy[1] === "asc"
                  ? "ascending"
                  : "descending"
                : null
            }
            onClick={() =>
              handleOrderByChange(
                orderBy[1] === "asc" ? ["name", "desc"] : ["name", "asc"],
              )
            }
          />
          <Table.HeaderCell
            width={4}
            content={"Number of Pieces"}
            sorted={
              orderBy[0] === "number_of_pieces"
                ? orderBy[1] === "asc"
                  ? "ascending"
                  : "descending"
                : null
            }
            onClick={() =>
              handleOrderByChange(
                orderBy[1] === "asc"
                  ? ["number_of_pieces", "desc"]
                  : ["number_of_pieces", "asc"],
              )
            }
          />
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {locations
          .map((data) => new Location(data))
          .map((location) => (
            <LocationTile
              key={location._id}
              locationId={location._id}
              storageLocation={location}
            />
          ))}
      </Table.Body>
    </Table>
  );
};

export default LocationsTable;
