import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Header, Segment } from "semantic-ui-react";
import { getSLPAE } from "../../../../../../redux/actions/artist";
import { ResponsiveBar } from "@nivo/bar";
import ErrorMessage from "../../../../../../components/ErrorMessage";

const ArtistSoldLotPerformanceAgainstEstimate = ({ artistId, fetchData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetch = async () => {
    setIsLoading(true);
    try {
      const { payload } = await fetchData(artistId);
      setData(payload.data);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!artistId) return;
    fetch();
  }, [artistId]);

  // convert D9F9E1 to hsl value

  const colors = {
    above: "hsl(135, 73%, 91%)",
    within: "hsl(37, 100%, 68%)",
    below: "hsl(358, 93%, 84%)",
    not_sold: "hsl(229, 4%, 48%)",
  };
  const getColor = (bar) => colors[bar.id];

  const chartData = (data ?? []).map((d) => {
    return {
      _id: d._id,
      above: Math.round((d.above / d.total) * 100),
      within: Math.round((d.within / d.total) * 100),
      below: Math.round((d.below / d.total) * 100),
      not_sold: Math.round((d.not_sold / d.total) * 100),
    };
  });

  return (
    <Segment basic compact loading={isLoading}>
      <Header
        textAlign={"center"}
        content={"Sold Lot Performance Against Estimate"}
        subheader={``}
      />
      <p>
        Tracks the performance of lots against their estimate, indicating how
        many sold within, above or below this range, or remained unsold. This
        chart shows how the artist's market performed compared to pre-sale
        expectations. For example, a rise in the proportion of unsold works may
        indicate that supply is outstripping demand and could lead to a future
        drop in prices.
      </p>
      <div style={{ height: 400 }}>
        {error && <ErrorMessage error={error} />}
        {chartData.length === 0 && <p>No data</p>}
        {chartData.length > 0 && (
          <ResponsiveBar
            data={chartData}
            keys={["not_sold", "below", "within", "above"]}
            label={(d) => `${d.value}%`}
            tooltip={({ id, value }) => {
              return (
                <div
                  style={{
                    background: "white",
                    padding: "9px 12px",
                    border: "1px solid #ccc",
                  }}
                >
                  <div>
                    {value}% sold {id}
                  </div>
                </div>
              );
            }}
            value={(d) => `${d.value}% of Lots`}
            ariaLabel={(d) => `${d.value}%`}
            indexBy="_id"
            margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
            padding={0.3}
            valueScale={{ type: "linear" }}
            indexScale={{ type: "band", round: true }}
            colors={getColor}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Year",
              legendPosition: "middle",
              legendOffset: 32,
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Performance",
              legendPosition: "middle",
              legendOffset: -40,
            }}
          />
        )}
      </div>
    </Segment>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchData: (id) => dispatch(getSLPAE(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArtistSoldLotPerformanceAgainstEstimate);
