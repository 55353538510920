import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Loader, Segment, Header } from "semantic-ui-react";
import { useParams } from "react-router-dom";
import { getGroupingPricePredictions } from "../../../redux/actions/grouping";
import ErrorMessage from "../../../components/ErrorMessage";
import { ResponsiveLine } from "@nivo/line";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const PricePredictions = ({ fetch, error, loading, user, currencies }) => {
  const { groupingId } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, [groupingId]);

  const fetchData = async () => {
    try {
      const { payload } = await fetch(groupingId);
      setData(payload.data);
    } catch (e) {
      console.log(e);
    }
  };

  if (!data) return null;

  const actualData = (data.past_data ?? []).map((d) => ({
    x: d.year,
    y: d.predictedPrice / 100,
  }));
  const predictedData = (data.future_data ?? []).map((d) => ({
    x: d.year,
    y: d.predictedPrice / 100,
  }));

  const isIncreasing =
    predictedData[0] && predictedData[1].y > predictedData[0].y;

  return (
    <>
      <Segment attached={"top"}>
        <Header content={"Market Trends"} />
      </Segment>
      <Segment attached>
        {loading && <Loader active inline={"centered"} />}
        {error && <ErrorMessage error={error} />}
        <div style={{ height: 400 }}>
          {error && <ErrorMessage error={error} />}
          <ResponsiveLine
            data={[
              {
                id: "actual",
                data: actualData,
                color: "#e76f51",
              },
              {
                id: "predicted",
                data: predictedData,
                color: isIncreasing ? "green" : "red",
                lineStyle: { lineDash: [6, 2] }, // Dashed line for predicted data
              },
            ]}
            colors={({ id, data, color }) => {
              return color;
            }}
            margin={{ top: 50, right: 50, bottom: 50, left: 80 }}
            xScale={{ type: "point" }}
            yScale={{
              type: "linear",
              min: "0",
              max: "auto",
              stacked: false,
              reverse: false,
            }}
            tick
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Year",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendOffset: -40,
              legendPosition: "middle",
              format: (d) => {
                return `$${d.toLocaleString()}`;
              },
            }}
            useMesh={true}
            // show tooltip with formatted price string
            tooltip={({ point }) => {
              return (
                <div
                  style={{
                    background: "white",
                    padding: "9px 12px",
                    border: "1px solid #ccc",
                  }}
                >
                  <div>
                    <strong>{point.data.x}</strong>
                  </div>
                  <div>
                    <div>
                      <strong>{formatter.format(point.data.yFormatted)}</strong>
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </Segment>
      <Segment attached={"bottom"}>
        <Header
          size="tiny"
          content={
            "*Average annual growth rate, calculated from public auction data over the last 5 years."
          }
        />
      </Segment>
    </>
  );
};

const mapStateToProps = ({ firebase, currencies }) => ({
  user: firebase.profile || null,
  currencies: currencies.currencies,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(getGroupingPricePredictions(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PricePredictions);
