const options = [
  { key: 0.0, text: "0", value: 0 },
  { key: 0.1667, text: "⅙", value: 0.1667 },
  { key: 0.125, text: "⅛", value: 0.125 },
  { key: 0.1875, text: "3/16", value: 0.1875 },
  { key: 0.25, text: "¼", value: 0.25 },
  { key: 0.3125, text: "5/16", value: 0.3125 },
  { key: 0.375, text: "⅜", value: 0.375 },
  { key: 0.4375, text: "7/16", value: 0.4375 },
  { key: 0.5, text: "½", value: 0.5 },
  { key: 0.5625, text: "9/16", value: 0.5625 },
  { key: 0.625, text: "⅝", value: 0.625 },
  { key: 0.6875, text: "11/16", value: 0.6875 },
  { key: 0.75, text: "¾", value: 0.75 },
  { key: 0.8125, text: "13/16", value: 0.8125 },
  { key: 0.875, text: "⅞", value: 0.875 },
  { key: 0.9375, text: "15/16", value: 0.9375 },
];

const measurement = (value) => {
  const total = Number(value).toFixed(4);
  const whole = Math.trunc(total);
  const fraction = (total % 1).toFixed(4);
  return [whole, fraction];
};

const sizeString = (width, height, depth, measurment_units) => {
  if (!width && !height && !depth) {
    return null;
  }

  let string = "";

  if (height) {
    if (measurment_units === "metric") {
      string = string + height.toString();
    } else {
      string = string + `${measurement(height)[0]}`;

      const option = options.filter(
        (o) => o.key === Number(measurement(height)[1]),
      )[0];

      if (option && option.text !== "0") {
        string = string + ` ${option.text}`;
      }
    }
  }

  if (width && string !== "") {
    string = string + " x ";
  }

  if (width) {
    if (measurment_units === "metric") {
      string = string + width.toString();
    } else {
      string = string + `${measurement(width)[0]}`;

      const option = options.filter(
        (o) => o.key === Number(measurement(width)[1]),
      )[0];

      if (option && option.text !== "0") {
        string = string + ` ${option.text}`;
      }
    }
  }

  if (depth && string !== "") {
    string = string + " x ";
  }

  if (depth) {
    if (measurment_units === "metric") {
      string = string + depth.toString();
    } else {
      string = string + `${measurement(depth)[0]}`;

      const option = options.filter(
        (o) => o.key === Number(measurement(depth)[1]),
      )[0];

      if (option && option.text !== "0") {
        string = string + ` ${option.text}`;
      }
    }
  }

  if (string !== "") {
    string = string + (measurment_units === "metric" ? " cm" : " in.");
  }

  return string;
};

const priceString = (price, currency = "USD", user, currencies) => {
  if (!price) {
    return null;
  }

  if (user && currencies && user.preferred_currency !== currency) {
    const currenciesBySymbol = currencies.reduce(
      (acc, currency) => ({
        ...acc,
        [currency.symbol]: currency.value,
      }),
      {},
    );

    const cost_currency = currenciesBySymbol[currency || "USD"];
    const euros_cost = price / cost_currency;
    const to_currency = currenciesBySymbol[user.preferred_currency || "USD"];
    const cost = to_currency * euros_cost;

    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: user.preferred_currency || "USD",
      minimumFractionDigits: 2,
    }).format(cost);
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: ((currency && currency.toUpperCase()) || "USD").toUpperCase(),
    minimumFractionDigits: 2,
  }).format(price);
};

const estimateString = (low, high, currency, user, currencies) => {
  if (!low && !high) {
    return "Estimate Upon Request";
  }

  if (user && currencies && user.preferred_currency !== currency) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: user.preferred_currency || "USD",
      minimumFractionDigits: 2,
    });

    const cost_currency = currencies.filter(
      (r) => r.symbol === (currency || "USD"),
    )[0]?.value;

    const to_currency = currencies.filter(
      (r) => r.symbol === (user.preferred_currency || "USD"),
    )[0]?.value;

    if (cost_currency && to_currency) {
      return `${formatter.format(
        to_currency * (low / cost_currency),
      )} - ${formatter.format(to_currency * (high / cost_currency))}`;
    }
  } else if (low && high) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency || "USD",
      minimumFractionDigits: 2,
    });
    return `${formatter.format(low)} - ${formatter.format(high)}`;
  }
  return "Estimate Upon Request";
};

const editionString = (edition_number, edition_size) => {
  if (!edition_number && !edition_size) {
    return null;
  }

  if (!edition_number) {
    return `Edition of ${edition_size}`;
  }

  if (!edition_size) {
    return edition_number;
  }

  return `${edition_number} / ${edition_size}`;
};

export { sizeString, priceString, estimateString, editionString };
