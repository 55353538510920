import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Breadcrumb,
  Button,
  Grid,
  Header,
  Image,
  Segment,
  Transition,
  Loader,
  Confirm,
} from "semantic-ui-react";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  getArtist,
  deleteArtist,
  resetArtistState,
} from "../../../../../../redux/actions/artist";
import placeholder from "../../../../../../images/profile_placeholder.png";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import { capitalize } from "lodash";
import EditArtistSidePane from "../../../../components/EditArtistSidePane";
import ArtistExtra from "../ArtistExtra";
import ArtistFollowButton from "../ArtistFollowButton/ArtistFollowButton";
import AuthLayout from "../../../../../../layouts/AuthLayout/AuthLayout";
import { ARTIST_PATH, DASHBOARD_PATH } from "../../../../../../constants/paths";
import Notes from "../../../../../../components/Modals/Notes";

const ArtistDetail = ({
  user,
  artist,
  fetch,
  reset,
  error,
  loading,
  remove,
}) => {
  const navigate = useNavigate();
  const { artistId } = useParams();
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(null);

  useEffect(() => {
    fetch(artistId);
  }, [artistId]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const dates = (artist) => {
    if (!artist) return;
    const { birth_year, death_year, nationality } = artist;
    if (!birth_year && !death_year && !nationality) return;

    var text = "";

    if (birth_year && death_year) {
      text = `${birth_year} - ${death_year}`;
    } else if (birth_year) {
      text = `Born ${birth_year}`;
    } else if (death_year) {
      text = `Died ${death_year}`;
    }

    if (text.length > 0 && nationality) {
      text += " \u2022 ";
    }

    if (nationality) {
      text += capitalize(nationality);
    }

    return text;
  };

  const handleDelete = async () => {
    try {
      await remove(artist._id);
      navigate(-1);
    } catch (e) {}
  };

  return (
    <AuthLayout title={artist ? artist.name : "Loading..."} bannersEnabled={false}>
      {loading && <Loader active inline={"centered"} />}
      {!loading && (
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Segment basic>
                <Breadcrumb>
                  <Breadcrumb.Section
                    as={Link}
                    to={DASHBOARD_PATH}
                    content={"Home"}
                  />
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section
                    as={Link}
                    to={ARTIST_PATH}
                    content={"Artists"}
                  />
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section active content={artist && artist.name} />
                </Breadcrumb>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              {artist && user && user.roles && user.roles.includes("admin") && (
                <>
                  <Button
                    negative
                    icon={"trash"}
                    floated="right"
                    content={"Delete"}
                    onClick={() => setConfirmDeleteOpen(true)}
                  />
                  <Confirm
                    open={confirmDeleteOpen}
                    onCancel={() => setConfirmDeleteOpen(false)}
                    onConfirm={handleDelete}
                    content="Are you sure you want to delete this artist and all of their associated records?"
                  />
                </>
              )}
              {artist && (
                <EditArtistSidePane artist={artist}>
                  <Button
                    basic
                    floated={"right"}
                    icon={"edit"}
                    content={"Edit Artist"}
                    labelPosition={"left"}
                  />
                </EditArtistSidePane>
              )}
              {user && artist && <ArtistFollowButton artistId={artistId} />}
            </Grid.Column>
          </Grid.Row>

          {error && (
            <Grid.Row>
              <Grid.Column width={16}>
                <ErrorMessage error={error} />
              </Grid.Column>
            </Grid.Row>
          )}

          <Grid.Row>
            <Grid.Column width={8}>
              <Transition.Group
                style={{ maxHeight: "40em", minHeight: "20em" }}
                as={Image}
                animation={"fade left"}
                duration={600}
                visible={artist}
                centered
                src={(artist && artist.image_url) || placeholder}
              />

              <Transition.Group
                basic
                as={Segment}
                animation={"fade up"}
                duration={600}
                visible={artist}
              >
                <div style={{ textAlign: "center" }}>
                  {artist && artist.website_url && (
                    <Button
                      basic
                      icon={"globe"}
                      style={{ marginTop: "8px" }}
                      labelPosition={"left"}
                      content={"View Website"}
                      as="a"
                      href={artist.website_url}
                      rel="noopener noreferrer"
                      target="_blank"
                    />
                  )}
                  {artist && artist.instagram_username && (
                    <Button
                      style={{ marginTop: "8px" }}
                      color="instagram"
                      icon={"instagram"}
                      labelPosition={"left"}
                      content={"View Instagram"}
                      as="a"
                      href={`https://instagram.com/${artist.instagram_username}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    />
                  )}
                  {artist && artist.twitter_username && (
                    <Button
                      basic
                      style={{ marginTop: "8px" }}
                      icon={"twitter"}
                      labelPosition={"left"}
                      content={"View Twitter"}
                      as="a"
                      href={`https://twitter.com/${artist.twitter_username}`}
                      rel="noopener noreferrer"
                      target="_blank"
                    />
                  )}
                  {artist && artist.email && (
                    <Button
                      basic
                      style={{ marginTop: "8px" }}
                      icon={"mail"}
                      labelPosition={"left"}
                      content={"Email Artist"}
                      as="a"
                      href={artist.email}
                      rel="noopener noreferrer"
                      target="_blank"
                    />
                  )}
                  <Notes style={{ marginTop: "8px" }} artistID={artistId} />
                </div>
              </Transition.Group>
            </Grid.Column>
            <Grid.Column width={8}>
              <Transition.Group
                basic
                as={Segment}
                animation={"fade down"}
                duration={600}
                visible={artist}
              >
                {artist && (
                  <Header
                    size="huge"
                    content={artist.name}
                    subheader={dates(artist)}
                  />
                )}
              </Transition.Group>
              <Transition.Group
                as={Segment}
                animation={"fade up"}
                duration={600}
                visible={artist}
              >
                {artist && <p>{artist.biography}</p>}
              </Transition.Group>
            </Grid.Column>
          </Grid.Row>
          {artist && <ArtistExtra artist={artist} />}
        </Grid>
      )}
    </AuthLayout>
  );
};

const mapStateToProps = ({ firebase, artist }) => ({
  user: firebase.profile || null,
  artist: artist.artist,
  error: artist.error,
  loading: artist.isLoadingArtist,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(getArtist(id)),
  remove: (id) => dispatch(deleteArtist(id)),
  reset: () => dispatch(resetArtistState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistDetail);
