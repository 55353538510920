import React from "react";
import { useSelector } from "react-redux";
import { Provider } from "react-redux";
import {
  ReactReduxFirebaseProvider,
  firebaseReducer,
} from "react-redux-firebase";
import { createFirestoreInstance, firestoreReducer } from "redux-firestore"; // <- needed if using firestore
import { combineReducers } from "redux";
import { store } from "../../redux/store";
import { app as firebase } from "../../utils/firebase";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Loader } from "semantic-ui-react";

import LoginPage from "../../routes/Auth/components/FirebasePage";
import RegisterPage from "../../routes/Register/components/RegisterPage";
import DashboardPage from "../../routes/Dashboard/components/DashboardPage";
import ArtistsList from "../../routes/Artists/components/ArtistsList";
import ArtistDetails from "../../routes/Artists/routes/Artist/components/ArtistDetails";

import GalleriesList from "../../routes/Galleries/components/GalleriesList";
import GalleryDetails from "../../routes/Galleries/routes/Gallery/components/GalleryDetails";

import LocationsList from "../../routes/Locations/components/LocationsList";
import LocationDetails from "../../routes/Locations/routes/Location/components/LocationDetails";

import PiecesList from "../../routes/Pieces/components/PiecesList";
import PieceDetails from "../../routes/Pieces/routes/Piece/components/PieceDetails";

import AuctionsList from "../../routes/Auctions/components/AuctionsList";
import AdminAuctionsList from "../../routes/Auctions/components/AdminAuctionsList";
import AuctionDetails from "../../routes/Auctions/routes/Auction/components/AuctionDetails";

import AuctionHouseList from "../../routes/AuctionHouses/components/AuctionHousesList";
import AuctionHouseDetails from "../../routes/AuctionHouses/routes/AuctionHouse/components/AuctionHouseDetails/";

import MuseumList from "../../routes/Museums/components/MuseumsList";
import MuseumDetails from "../../routes/Museums/routes/Museum/components/MuseumDetails";

import AuctionRecordDetails from "../../routes/AuctionRecords/routes/AuctionRecord/components/AuctionRecordDetails";

import PrivateRoomList from "../../routes/PrivateRooms/components/PrivateRoomsList";
import PrivateRoomDetails from "../../routes/PrivateRooms/routes/PrivateRoom/components/PrivateRoomDetails";

import ProductsList from "../../routes/Product/components/ProductsList";
import ProductDetails from "../../routes/Product/routes/Product/components/ProductDetails";

import ArtworksList from "../../routes/Artworks/components/ArtworksList";
import ArtworkDetails from "../../routes/Artworks/routes/Artwork/components/ArtworkDetails";

import AppraisalRequest from "../../routes/AppraisalRequest/AppraisalRequestPage";

import AccountPage from "../../routes/Account/components/AccountPage";
import ImportPage from "../../routes/Import/components/ImportPage";
import GroupingDetail from "../../routes/GroupingDetail";

import InvitationDetail from "../../routes/Invitation/routes/Invitation/components/InvitationDetail";

import {
  ARTIST_PATH,
  LOGIN_PATH,
  GALLERY_PATH,
  LOCATION_PATH,
  PIECE_PATH,
  AUCTION_PATH,
  AUCTION_RECORD_PATH,
  AUCTION_HOUSE_PATH,
  MUSEUM_PATH,
  PRIVATE_ROOM_PATH,
  APPRAISAL_REQUEST_PATH,
  ACCOUNT_PATH,
  PRODUCT_PATH,
  DASHBOARD_PATH,
  REGISTER_PATH,
  ARTWORKS_PATH,
  IMPORT_PATH,
  INVITATIONS_PATH,
  FIREBASE_PATH,
  GROUPINGS_PATH,
} from "../../constants/paths";
import CoreLayout from "../../layouts/CoreLayout";
import GroupingList from "../../routes/GroupingList";
import ArtistGroupingList from "../../routes/ArtistGroupingList";
import AuctionRecordsList from "../../routes/AuctionRecords/components/AuctionRecordsList";

const rrfConfig = {
  userProfile: "users", // root that user profiles are written to
  updateProfileOnLogin: false, // enable/disable updating of profile on login
  sessions: null, // Skip storing of sessions
  useFirestoreForProfile: true, // Save profile to Firestore instead of Real Time Database
  useFirestoreForStorageMeta: true, // Metadata associated with storage file uploads goes to Firestore
  autoPopulateProfile: true,
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
};

export const AuthenticatedRoute = ({ element }) => {
  const auth = useSelector((state) => state.firebase.auth);
  if (!auth.isLoaded) return <Loader active />;
  if (auth.isEmpty) return <Navigate to={LOGIN_PATH} replace />;
  return element;
};

export const UnauthenticatedRoute = ({ element }) => {
  const auth = useSelector((state) => state.firebase.auth);
  if (!auth.isLoaded) return <Loader active />;
  if (!auth.isEmpty) return <Navigate to={DASHBOARD_PATH} replace />;
  return element;
};

const App = ({}) => {
  return (
    <Provider store={store}>
      <ReactReduxFirebaseProvider
        {...rrfProps}
        createFirestoreInstance={createFirestoreInstance}
      >
        <BrowserRouter>
          <CoreLayout>
            <Routes>
              <Route
                path="/"
                element={<AuthenticatedRoute element={<DashboardPage />} />}
              />
              <Route
                path={LOGIN_PATH}
                element={<UnauthenticatedRoute element={<LoginPage />} />}
              />
              <Route
                path={FIREBASE_PATH}
                element={<LoginPage />}
              />
              <Route
                path={REGISTER_PATH}
                element={<UnauthenticatedRoute element={<RegisterPage />} />}
              />

              <Route path={ARTIST_PATH}>
                <Route
                  index={true}
                  element={<AuthenticatedRoute element={<ArtistsList />} />}
                />
                <Route
                  path={`:artistId`}
                  element={<AuthenticatedRoute element={<ArtistDetails />} />}
                />
                <Route
                  path={`:artistId${GROUPINGS_PATH}`}
                  element={
                    <AuthenticatedRoute element={<ArtistGroupingList />} />
                  }
                />
              </Route>

              <Route path={GALLERY_PATH}>
                <Route
                  index={true}
                  element={<AuthenticatedRoute element={<GalleriesList />} />}
                />
                <Route
                  path={`:galleryId`}
                  element={<AuthenticatedRoute element={<GalleryDetails />} />}
                />
              </Route>

              <Route path={LOCATION_PATH}>
                <Route
                  index={true}
                  element={<AuthenticatedRoute element={<LocationsList />} />}
                />
                <Route
                  path={`:locationId`}
                  element={<AuthenticatedRoute element={<LocationDetails />} />}
                />
              </Route>

              <Route path={PIECE_PATH}>
                <Route
                  index={true}
                  element={<AuthenticatedRoute element={<PiecesList />} />}
                />
                <Route
                  path={`:pieceId`}
                  element={<AuthenticatedRoute element={<PieceDetails />} />}
                />
              </Route>

              <Route path={GROUPINGS_PATH}>
                <Route
                  index={true}
                  element={<AuthenticatedRoute element={<GroupingList />} />}
                />
                <Route
                  path={`:groupingId`}
                  element={<AuthenticatedRoute element={<GroupingDetail />} />}
                />
              </Route>

              <Route path={AUCTION_PATH}>
                <Route
                  index={true}
                  element={
                    <AuthenticatedRoute
                      element={
                        localStorage.getItem("role") === "admin" ? (
                          <AdminAuctionsList />
                        ) : (
                          <AuctionsList />
                        )
                      }
                    />
                  }
                />
                <Route
                  path={`:auctionId`}
                  element={<AuthenticatedRoute element={<AuctionDetails />} />}
                />
              </Route>

              <Route path={AUCTION_RECORD_PATH}>
                <Route
                  index={true}
                  element={<AuthenticatedRoute element={<AuctionRecordsList />} />}
                />
                <Route
                  path={`:auctionRecordId`}
                  element={
                    <AuthenticatedRoute element={<AuctionRecordDetails />} />
                  }
                />
              </Route>

              <Route path={AUCTION_HOUSE_PATH}>
                <Route
                  index={true}
                  element={
                    <AuthenticatedRoute element={<AuctionHouseList />} />
                  }
                />
                <Route
                  path={`:auctionHouseId`}
                  element={
                    <AuthenticatedRoute element={<AuctionHouseDetails />} />
                  }
                />
              </Route>

              <Route path={MUSEUM_PATH}>
                <Route
                  index={true}
                  element={<AuthenticatedRoute element={<MuseumList />} />}
                />
                <Route
                  path={`:museumId`}
                  element={<AuthenticatedRoute element={<MuseumDetails />} />}
                />
              </Route>

              <Route path={PRIVATE_ROOM_PATH}>
                <Route
                  index={true}
                  element={<AuthenticatedRoute element={<PrivateRoomList />} />}
                />
                <Route
                  path={`:privateRoomId`}
                  element={
                    <AuthenticatedRoute element={<PrivateRoomDetails />} />
                  }
                />
              </Route>

              <Route path={APPRAISAL_REQUEST_PATH}>
                <Route
                  index={true}
                  element={
                    <AuthenticatedRoute element={<AppraisalRequest />} />
                  }
                />
              </Route>

              <Route path={PRODUCT_PATH}>
                <Route
                  index={true}
                  element={<AuthenticatedRoute element={<ProductsList />} />}
                />
                <Route
                  path={`:productId`}
                  element={<AuthenticatedRoute element={<ProductDetails />} />}
                />
              </Route>

              <Route path={ARTWORKS_PATH}>
                <Route
                  index={true}
                  element={<AuthenticatedRoute element={<DashboardPage />} />}
                />
                <Route
                  path={`:artworkId`}
                  element={<AuthenticatedRoute element={<ArtworkDetails />} />}
                />
              </Route>

              <Route path={ACCOUNT_PATH}>
                <Route
                  index
                  element={<AuthenticatedRoute element={<AccountPage />} />}
                />
                <Route
                  path="*"
                  element={<AuthenticatedRoute element={<AccountPage />} />}
                />
              </Route>

              <Route path={INVITATIONS_PATH}>
                <Route
                  index={true}
                  element={<AuthenticatedRoute element={<DashboardPage />} />}
                />
                <Route
                  path={`:invitationID`}
                  element={
                    <AuthenticatedRoute element={<InvitationDetail />} />
                  }
                />
              </Route>

              <Route path={IMPORT_PATH}>
                <Route
                  index={true}
                  element={<AuthenticatedRoute element={<ImportPage />} />}
                />
              </Route>
            </Routes>
          </CoreLayout>
        </BrowserRouter>
      </ReactReduxFirebaseProvider>
    </Provider>
  );
};

App.propTypes = {};

export default App;
