import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  Button,
  Grid,
  Header,
  Icon,
  Popup,
  Segment,
  Table,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getProductList } from "../../../../redux/actions/product";
import { PRODUCT_PATH } from "../../../../constants/paths";
import {
  premiumBenefits,
  proBenefits,
  businessBenefits,
} from "../../../../common";

const ProductsList = ({ error, products, fetchAll }) => {
  useEffect(() => {
    if (!products && !error) {
      fetchAll();
    }
  });

  return (
    <Grid stackable style={{ textAlign: "center" }} centered>
      <Grid.Row columns={1}>
        <Grid.Column>
          <Header
            textAlign={"center"}
            size={"huge"}
            content={"Pricing & Plans"}
            subheader={"Please choose a plan below"}
          />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row columns={4}>
        <Grid.Column>
          <Segment attached={"top"} style={{ backgroundColor: "black" }}>
            <Header inverted content={"Premium"} textAlign={"center"} />
          </Segment>
          <Segment attached>
            <Header
              textAlign={"center"}
              style={{ padding: "1em" }}
              content={
                "Document 50 artworks, unlimited locations, and 1 private room."
              }
            />
            <Table striped textAlign={"center"}>
              <Table.Body>
                {premiumBenefits.map((b) => (
                  <Popup
                    header={b.header}
                    content={b.content}
                    trigger={
                      <Table.Row disabled={!b.enabled}>
                        <Table.Cell textAlign={"left"} content={b.header} />
                        <Table.Cell textAlign={"left"} width={1}>
                          <Icon name={b.icon} />
                        </Table.Cell>
                      </Table.Row>
                    }
                  />
                ))}
              </Table.Body>
            </Table>
          </Segment>
          <Segment attached={"bottom"}>
            <Button
              fluid
              size={"huge"}
              content={"$10 a month"}
              color={"black"}
              as={Link}
              to={`${PRODUCT_PATH}/prod_GtoIm9e69scbOt`}
            />
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment attached={"top"} style={{ backgroundColor: "black" }}>
            <Header inverted content={"Pro"} textAlign={"center"} />
          </Segment>
          <Segment attached>
            <Header
              textAlign={"center"}
              style={{ padding: "1em" }}
              content={
                "Document unlimited artworks, locations, and private rooms."
              }
            />
            <Table striped textAlign={"center"}>
              <Table.Body>
                {proBenefits.map((b) => (
                  <Popup
                    header={b.header}
                    content={b.content}
                    trigger={
                      <Table.Row disabled={!b.enabled}>
                        <Table.Cell textAlign={"left"} content={b.header} />
                        <Table.Cell textAlign={"left"} width={1}>
                          <Icon name={b.icon} />
                        </Table.Cell>
                      </Table.Row>
                    }
                  />
                ))}
              </Table.Body>
            </Table>
          </Segment>
          <Segment attached={"bottom"}>
            <Button
              fluid
              size={"huge"}
              content={"$25 a month"}
              color={"black"}
              as={Link}
              to={`${PRODUCT_PATH}/prod_GmPwWEPGSWMIJ8`}
            />
          </Segment>
        </Grid.Column>

        <Grid.Column>
          <Segment attached={"top"} style={{ backgroundColor: "black" }}>
            <Header inverted content={"Business"} textAlign={"center"} />
          </Segment>
          <Segment attached>
            <Header
              textAlign={"center"}
              style={{ padding: "1em" }}
              content={
                "Document unlimited artworks, locations, and private rooms. \n"
              }
            />
            <Table striped textAlign={"center"}>
              <Table.Body>
                {businessBenefits.map((b) => (
                  <Popup
                    header={b.header}
                    content={b.content}
                    trigger={
                      <Table.Row disabled={!b.enabled}>
                        <Table.Cell textAlign={"left"} content={b.header} />
                        <Table.Cell textAlign={"left"} width={1}>
                          <Icon name={b.icon} />
                        </Table.Cell>
                      </Table.Row>
                    }
                  />
                ))}
              </Table.Body>
            </Table>
          </Segment>
          <Segment attached={"bottom"}>
            <Button
              fluid
              content={"Contact Us"}
              color={"black"}
              as={"a"}
              size={"huge"}
              href="mailto:info@artcollection.io?subject=Business Account Inquiry"
            />
          </Segment>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  );
};

const mapStateToProps = ({ products }) => ({
  products: products.products,
  loading: products.isLoadingProductList,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAll: () => dispatch(getProductList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsList);
