import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Breadcrumb,
  Button,
  Confirm,
  Grid,
  Header,
  Image,
  Input,
  Loader,
  Menu,
  Segment,
  Transition,
} from "semantic-ui-react";
import { Link, useNavigate, useParams } from "react-router-dom";

import {
  deleteLocation,
  getLocation,
  resetLocationState,
} from "../../../../../../redux/actions/location";
import placeholder from "../../../../../../images/profile_placeholder.png";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import EditLocationSidePane from "../../../../components/EditLocationSidePane";
import LocationPieceRecords from "../LocationPieceRecords";
import AuthLayout from "../../../../../../layouts/AuthLayout/AuthLayout";
import { Mixpanel } from "../../../../../../utils/Mixpanel";
import { set } from "lodash";
import {
  DASHBOARD_PATH,
  LOCATION_PATH,
} from "../../../../../../constants/paths";

const LocationDetail = ({ location, fetch, reset, remove }) => {
  const navigate = useNavigate();
  const { locationId } = useParams();

  const [error, setError] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loadData = async () => {
    setError(null);
    setIsLoading(true);
    try {
      await fetch(locationId);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadData();
    Mixpanel.page("location", { location: locationId });
  }, [locationId]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const handleConfirm = async () => {
    setError(null);

    try {
      await remove(locationId);
      navigate(-1);
    } catch (e) {
      setError(e);
    }
  };

  return (
    <AuthLayout
      title={location ? location.name : "Loading..."}
      description={location && location.about && location.about.slice(0, 100)}
    >
      {isLoading && <Loader active inline={"centered"} />}
      {!isLoading && (
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Segment basic>
                <Breadcrumb>
                  <Breadcrumb.Section
                    as={Link}
                    to={DASHBOARD_PATH}
                    content={"Home"}
                  />
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section
                    as={Link}
                    to={LOCATION_PATH}
                    content={"Locations"}
                  />
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section
                    active
                    content={location && location.name}
                  />
                </Breadcrumb>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              <Button
                basic
                floated="right"
                onClick={() => setConfirmOpen(true)}
                content="Delete Location"
                icon="close"
                labelPosition="left"
              />
              <Confirm
                open={confirmOpen}
                onCancel={() => setConfirmOpen(false)}
                onConfirm={handleConfirm}
                content={"Are you sure you want to delete this Location?"}
              />

              {location && (
                <EditLocationSidePane location={location}>
                  <Button
                    basic
                    floated={"right"}
                    icon={"edit"}
                    content={"Edit Location"}
                    labelPosition={"left"}
                  />
                </EditLocationSidePane>
              )}
            </Grid.Column>
          </Grid.Row>

          {error && (
            <Grid.Row>
              <Grid.Column width={16}>
                <ErrorMessage error={error} />
              </Grid.Column>
            </Grid.Row>
          )}

          <Grid.Row>
            <Grid.Column width={8}>
              <Transition.Group
                style={{ maxHeight: "40em", minHeight: "20em" }}
                as={Image}
                animation={"fade left"}
                duration={600}
                visible={location}
                centered
                src={(location && location.image_url) || placeholder}
              />
              <Transition.Group
                basic
                as={Segment}
                animation={"fade up"}
                duration={600}
                visible={location}
              >
                <div style={{ textAlign: "center", marginTop: "1em" }}>
                  {location && location.website_url && (
                    <Button
                      basic
                      icon={"globe"}
                      labelPosition={"left"}
                      content={"View Website"}
                      as="a"
                      href={location.website_url}
                      rel="noopener noreferrer"
                      target="_blank"
                    />
                  )}
                  {location && location.email && (
                    <Button
                      basic
                      icon={"mail"}
                      labelPosition={"left"}
                      content={"Contact Location"}
                      as="a"
                      href={location.email}
                      rel="noopener noreferrer"
                      target="_blank"
                    />
                  )}
                </div>
              </Transition.Group>
            </Grid.Column>
            <Grid.Column width={8}>
              <Transition.Group
                basic
                as={Segment}
                animation={"fade down"}
                duration={600}
                visible={location}
              >
                {location && <Header size="huge" content={location.name} />}
              </Transition.Group>
              {location && location.about && (
                <Transition.Group
                  as={Segment}
                  animation={"fade up"}
                  duration={600}
                  visible={location}
                >
                  <p>{location.about}</p>
                </Transition.Group>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              {location && <LocationPieceRecords locationId={locationId} />}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </AuthLayout>
  );
};

const mapStateToProps = ({ firebase, location }) => ({
  user: firebase.profile || null,
  location: location.location,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(getLocation(id)),
  remove: (id) => dispatch(deleteLocation(id)),
  reset: () => dispatch(resetLocationState()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetail);
