import React, { useEffect } from "react";
import { connect } from "react-redux";
import { getArtworkList } from "../../../../redux/actions/artwork";

import { Mixpanel } from "../../../../utils/Mixpanel";
import AuthLayout from "../../../../layouts/AuthLayout/AuthLayout";
import { Grid, Header, Segment, Table } from "semantic-ui-react";
import artworks from "../../../../redux/modules/artworks";
import InfiniteScroll from "react-infinite-scroller";
import ArtworkTile from "../ArtworkTile";

const ArtworksList = ({ fetchAll, loading, hasMoreItems, artworks, user }) => {
  useEffect(() => {
    Mixpanel.page("artwork");
  }, []);

  const items = (artworks || []).map((artwork) => (
    <ArtworkTile key={artwork._id} artwork={artwork} />
  ));

  return (
    <AuthLayout title={"Artworks"}>
      <Grid stackable>
        <Grid.Row verticalAlign={"middle"}>
          <Grid.Column width={16}>
            <Header size="huge" content="Artworks" />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            Found {artworks && artworks.size} artworks
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Segment basic loading={loading} style={{ padding: 0 }}>
              <InfiniteScroll
                initialLoad={true}
                loadMore={(page) => fetchAll(page)}
                hasMore={hasMoreItems}
                loader={
                  <Header
                    key={"loader"}
                    textAlign={"center"}
                    size={"small"}
                    content={"Loading ..."}
                  />
                }
              >
                <Table striped selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell width={8} content={"Name"} />
                      <Table.HeaderCell content={"Auction House"} />
                      <Table.HeaderCell content={"Date"} />
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>{items}</Table.Body>
                </Table>
              </InfiniteScroll>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ firebase, artworks }) => ({
  user: firebase.profile || null,
  artworks: artworks.artworks,
  loading: artworks.isLoadingArtworkList,
  error: artworks.error,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAll: (page) => dispatch(getArtworkList(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtworksList);
