import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Header, Segment } from "semantic-ui-react";
import { getSellThroughRates } from "../../../../../../redux/actions/artist";
import { ResponsiveLine } from "@nivo/line";
import ErrorMessage from "../../../../../../components/ErrorMessage";

const ArtistSellThroughRates = ({ artistId, fetchData }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  const fetch = async () => {
    setIsLoading(true);
    try {
      const response = await fetchData(artistId);
      setData(response.payload.data);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    if (!artistId) return;
    fetch();
  }, [artistId]);

  const total = (data || []).reduce(
    (acc, pilot) => acc + pilot.number_of_lots_total,
    0,
  );
  const lotsSold = (data || []).reduce(
    (acc, pilot) => acc + pilot.number_of_lots_sold,
    0,
  );

  const chartData = [
    {
      id: "sell through rate",
      data: data.map((d) => {
        return {
          y: Math.round((d.number_of_lots_sold / d.number_of_lots_total) * 100),
          x: d._id,
        };
      }),
    },
  ];

  return (
    <Segment basic compact loading={isLoading}>
      <Header
        textAlign={"center"}
        content={"Sell Through Rates"}
        subheader={`Average Total Rate: ${
          (lotsSold / total).toFixed(2) * 100
        } %`}
      />
      <p>
        An artist's sell-through rate (lots sold/total lots offered) is an
        important measure of market demand, which can help to reach buying or
        selling decisions. A high sell-through rate indicates strong demand for
        the lots offered at auction, and point to a liquid market for the
        artist. In general, low sell- through rates suggest a period of lesser
        demand.
      </p>

      <div style={{ height: 400 }}>
        {error && <ErrorMessage error={error} />}
        {chartData.length === 0 && <p>No data</p>}
        {chartData.length > 0 && (
          <ResponsiveLine
            data={chartData}
            margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
            yScale={{
              type: "linear",
              min: "0",
              max: "100",
              stacked: true,
              reverse: false,
            }}
            tick
            yFormat=" >-.2f"
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Year",
              legendOffset: 36,
              legendPosition: "middle",
            }}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Sell Through Rate",
              legendOffset: -40,
              legendPosition: "middle",
            }}
            pointSize={10}
            pointColor={{ theme: "background" }}
            pointBorderWidth={2}
            pointBorderColor={{ from: "serieColor" }}
            pointLabelYOffset={-12}
            useMesh={true}
          />
        )}
      </div>
    </Segment>
  );
};

const mapStateToProps = ({ firebase }) => ({
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({
  fetchData: (id) => dispatch(getSellThroughRates(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ArtistSellThroughRates);
