import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Grid,
  Header,
  Image,
  Segment,
  Transition,
  Loader,
  Confirm,
} from "semantic-ui-react";
import moment from "moment";
import { Link, useParams, ScollRestoration } from "react-router-dom";

import {
  deleteAuction,
  getAuction,
  resetAuctionState,
} from "../../../../../../redux/actions/auction";
import AuctionAuctionRecords from "../AuctionAuctionRecords";
import placeholder from "../../../../../../images/profile_placeholder.png";
import { Mixpanel } from "../../../../../../utils/Mixpanel";
import ErrorMessage from "../../../../../../components/ErrorMessage";
import EditAuctionSidePane from "../EditAuctionSidePane/EditAuctionSidePane";
import AuthLayout from "../../../../../../layouts/AuthLayout/AuthLayout";
import {
  AUCTION_HOUSE_PATH,
  AUCTION_PATH,
  DASHBOARD_PATH,
} from "../../../../../../constants/paths";

const AuctionDetail = ({
  auction,
  fetch,
  reset,
  user,
  error,
  loading,
  remove,
}) => {
  const { auctionId } = useParams();
  const navigate = useNavigate();
  const [deleteError, setDeleteError] = useState(null);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(null);

  useEffect(() => {
    fetch(auctionId);
    Mixpanel.page("auction", { auction: auctionId });
  }, [auctionId]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  const handleDelete = async () => {
    setDeleteError(null);
    try {
      await remove(auctionId);
      navigate(-1);
    } catch (e) {
      setDeleteError(e);
    }
  };

  return (
    <AuthLayout
      title={auction ? auction.name : "Loading..."}
      description={auction && auction.about && auction.about.slice(0, 100)}
    >
      {error && <ErrorMessage error={error} />}
      {deleteError && <ErrorMessage error={deleteError} />}
      {loading && <Loader active inline={"centered"} />}
      {!loading && (
        <Grid stackable>
          <Grid.Row columns={2}>
            <Grid.Column>
              <Segment basic>
                <Breadcrumb>
                  <Breadcrumb.Section
                    as={Link}
                    to={DASHBOARD_PATH}
                    content={"Home"}
                  />
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section
                    as={Link}
                    to={AUCTION_PATH}
                    content={"Auctions"}
                  />
                  <Breadcrumb.Divider />
                  <Breadcrumb.Section
                    active
                    content={auction && auction.name}
                  />
                </Breadcrumb>
              </Segment>
            </Grid.Column>
            <Grid.Column>
              {auction &&
                user &&
                user.roles &&
                user.roles.includes("admin") && (
                  <EditAuctionSidePane auction={auction}>
                    <Button
                      basic
                      floated={"right"}
                      icon={"edit"}
                      content={"Edit Auction"}
                      labelPosition={"left"}
                    />
                  </EditAuctionSidePane>
                )}
              {auction &&
                user &&
                user.roles &&
                user.roles.includes("admin") && (
                  <>
                    <Button
                      negative
                      icon={"trash"}
                      floated="right"
                      content={"Delete"}
                      onClick={() => setConfirmDeleteOpen(true)}
                    />
                    <Confirm
                      open={confirmDeleteOpen}
                      onCancel={() => setConfirmDeleteOpen(false)}
                      onConfirm={handleDelete}
                      content="Are you sure you want to delete this auction and all of their associated records?"
                    />
                  </>
                )}
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column width={8}>
              <Transition.Group
                style={{ maxHeight: "40em", minHeight: "20em" }}
                as={Image}
                animation={"fade left"}
                duration={600}
                visible={auction}
                centered
                src={(auction && auction.image_url) || placeholder}
              />
              <Transition.Group
                as={"div"}
                animation={"fade up"}
                duration={600}
                visible={auction}
              >
                <div style={{ textAlign: "center", marginTop: "1em" }}>
                  {auction && auction.website_url && (
                    <Button
                      basic
                      icon={"globe"}
                      labelPosition={"left"}
                      content={"View Auction Website"}
                      as="a"
                      href={auction.website_url}
                      rel="noopener noreferrer"
                      target="_blank"
                    />
                  )}
                </div>
              </Transition.Group>
            </Grid.Column>
            <Grid.Column width={8}>
              <Transition.Group
                basic
                as={Segment}
                animation={"fade down"}
                duration={600}
                visible={auction}
              >
                {auction && (
                  <Header size="large">
                    {auction.name}
                    <Header.Subheader>
                      {auction.start_date &&
                        moment(auction.start_date).format("MMM D, YYYY")}
                      {auction.start_date &&
                        auction.end_date &&
                        auction.start_date !== auction.end_date &&
                        ` - `}
                      {auction.end_date &&
                        auction.start_date !== auction.end_date &&
                        moment(auction.end_date).format("MMM D, YYYY")}
                      {" @ "}
                      <Link
                        to={{
                          pathname: `${AUCTION_HOUSE_PATH}/${auction.auction_house._id}`,
                        }}
                      >
                        {auction.auction_house.name}
                      </Link>
                    </Header.Subheader>
                  </Header>
                )}
              </Transition.Group>
              {auction && auction.about && (
                <Transition.Group
                  as={Segment}
                  animation={"fade up"}
                  duration={600}
                  visible={auction}
                  text
                >
                  <p>{auction.about}</p>
                </Transition.Group>
              )}
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <AuctionAuctionRecords auction={auction} />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </AuthLayout>
  );
};

const mapStateToProps = ({ auction, firebase }) => ({
  user: firebase.profile || null,
  auction: auction.auction,
  error: auction.error,
  loading: auction.isLoadingAuction,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(getAuction(id)),
  reset: () => dispatch(resetAuctionState()),
  remove: (id) => dispatch(deleteAuction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionDetail);
