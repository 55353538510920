import React, { useState, useRef, useEffect } from "react";
import {
  Form,
  Grid,
  Header,
  Image,
  Segment,
  Icon,
  Button,
  Divider,
} from "semantic-ui-react";
import DropZone from "../../../components/DropZone";
import AuthLayout from "../../../layouts/AuthLayout/AuthLayout";
import SelectPieceModal from "../../../components/Modals/Piece/Select";
import { createAppraisalRequest } from "../../../redux/actions/appraisal_request";
import { useSelector } from "react-redux";
import artfair from "../../../images/headers/artfair.png";
import { appraisalOptions } from "../../../common";
import ErrorMessage from "../../../components/ErrorMessage";

const AppraisalRequestPage = () => {

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [files, setFiles] = useState([]);
  const [formData, setFormData] = useState({
    reason: "valuation",
    description: "",
    email: "",
    phoneNumber: "",
    name: "",
    pieceID: null,
  });

  // Refs
  const user = useSelector((state) => state.firebase.profile);

  useEffect(() => {
    setFormData((formData) => ({
      ...formData,
      email: user.email || "",
      phoneNumber: user.phone_number || "",
      name: user.name || "",
    }));
  }, [user]);

  const handleChange = (e, { name, value }) => {
    setFormData({ ...formData, [name]: value });
  };

  const onAddImages = (images) => {
    setFiles([...files, ...images]);
  };

  const onRemoveImage = (imageID) => {
    setFiles(files.filter((a) => a.preview !== imageID));
  };

  const urlToObject = async (url) => {
    const response = await fetch(url);
    const blob = await response.blob();
    let file = new File([blob], "image.jpg", { type: blob.type });
    file.preview = url;
    return file;
  };

  const handlePieceSelected = async (piece) => {
    const description = [
      `Title: ${piece.title || "Untitled"}`,
      piece.date && `Creation Date: ${piece.date}`,
      piece.artist && `Artist: ${piece.artist.name}`,
      piece.medium && `Materials: ${piece.medium}`,
      piece.dimensionString && `Dimensions: ${piece.dimensionString}`,
      piece.editionString && `Edition Details: ${piece.editionString}`,
    ].filter(Boolean).join("\n");

    const images = await Promise.all(
      piece.images.map((image) => urlToObject(image.medium_image_url)),
    );
    setFiles(images);
    setFormData({ ...formData, description, pieceID: piece._id });
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError("");

    try {
      const submissionData = new FormData();
      Object.entries(formData).forEach(([key, value]) => {
        if (value !== null) submissionData.append(key, value);
      });
      files.forEach((file) => submissionData.append("images", file));

      await dispatch(createAppraisalRequest(submissionData));
      setFiles([]);
      setFormData({
        reason: "valuation",
        description: "",
        email: "",
        phoneNumber: "",
        name: "",
        pieceID: null,
      });
      window.scrollTo(0, 0);
    } catch (error) {
      setError(error.message || "An error occurred.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <AuthLayout title="Appraisal Request" bannersEnabled={false}>
      <Grid stackable centered>
        <Grid.Column width={12}>
          <Segment>
          <Image src={artfair} centered size="huge" />
            <Header size="huge" textAlign="center" content="Request an Appraisal"subheader={"Get an appraisal from a qualified professional"} />
            <Form loading={loading} error={!!error}>
              <Form.Field>
                <Header
                  size={"medium"}
                  content={"Reason"}
                  subheader={"What type of appraisal are you looking for?"}
                />
              </Form.Field>

              <Form.Select
                name="reason"
                label="Reason for Appraisal"
                options={appraisalOptions}
                value={formData.reason}
                onChange={handleChange}
                search
              />

              <Divider />

              <Form.Field>
                <Header
                  size={"medium"}
                  content={"Description"}
                  subheader={
                    "Provide a detailed description of the object (i.e. condition, artist or brand, material, provenance)."
                  }
                />
                                <SelectPieceModal onPieceSelected={handlePieceSelected} />

              </Form.Field>

              <Form.TextArea
                name="description"
                label="Description"
                placeholder="Provide a detailed description of the item"
                value={formData.description}
                onChange={handleChange}
              />

              <Form.Field>
                <label>Images</label>
                <DropZone
                  style={{ height: "140px" }}
                  onDrop={(acceptedFiles) => onAddImages(acceptedFiles)}
                  multiple={true}
                  icon={"image outline"}
                  placeholder={"Drag file here or click to upload"}
                  accept={"image/*"}
                />
                {(files || []).map(({ preview }) => (
                  <Image
                    key={preview}
                    size={"small"}
                    label={{
                      name: preview,
                      type: "new",
                      color: "red",
                      corner: "right",
                      onRemove: () => onRemoveImage(preview),
                    }}
                    src={preview}
                  />
                ))}
              </Form.Field>

              <Divider />

              <Form.Field>
                <Header
                  size={"medium"}
                  content={"Contact Information"}
                  subheader={"How can we reach you?"}
                />
              </Form.Field>
              <Form.Group widths="equal">
                <Form.Input
                  name="name"
                  label="Name"
                  placeholder="Name"
                  value={formData.name}
                  onChange={handleChange}
                />
                <Form.Input
                  name="email"
                  label="Email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
                <Form.Input
                  name="phoneNumber"
                  label="Phone Number"
                  placeholder="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </Form.Group>
              {error && <ErrorMessage error={error} />}
              <Button primary fluid large onClick={handleSubmit}>Submit</Button>
            </Form>
          </Segment>
          <Header size="tiny" textAlign="center" content="By clicking submit, you acknowledge that the information above will be shared with third-party service providers." />
          <Header as="h3" textAlign="center">
            Need help? Contact us at <a href="mailto:info@artcollection.io">info@artcollection.io</a>
          </Header>
          </Grid.Column>
        </Grid>
    </AuthLayout>
  );
};

export default AppraisalRequestPage