import { configureStore } from "@reduxjs/toolkit";
import axios from "axios";
import axiosMiddleware from "redux-axios-middleware";
import * as Sentry from "@sentry/react";
import reducer from "./reducer";
import { auth, appCheck } from "../utils/firebase";

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const middlewareConfig = {
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      {
        success: async ({ getState, dispatch, getSourceAction }, req) => {
          const headers = {
            ...req.headers,
            Role: localStorage.getItem("role") ?? "user",
            ...localStorage.getItem("user_id") && { user_id: localStorage.getItem("user_id") },
            ...auth.currentUser ? { Authorization: `JWT ${await auth.currentUser.getIdToken()}` } : {}
          };

          req.headers = headers;
          return req;
        },
        error: (context, error) => Promise.reject(error),
      },
    ],
    response: [
      {
        success: (context, res) => Promise.resolve(res),
        error: (context, error) => Promise.reject(error),
      },
    ],
  },
};

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(axiosMiddleware(client, middlewareConfig)),
  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
});

export default store;
