import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Grid, Header, Item, Segment } from "semantic-ui-react";
import { getMuseumList } from "../../../../redux/actions/museum";
import { Mixpanel } from "../../../../utils/Mixpanel";
import ErrorMessage from "../../../../components/ErrorMessage";
import MuseumsListTile from "../MuseumsListTile";
import AuthLayout from "../../../../layouts/AuthLayout/AuthLayout";

const MuseumsList = ({ fetchAll, error, museums, loading }) => {
  useEffect(() => {
    fetchAll(1);
    Mixpanel.page("museum");
  }, []);

  return (
    <AuthLayout title={"Museums"}>
      <Grid stackable>
        <Grid.Row verticalAlign={"middle"}>
          <Grid.Column width={16}>
            <Header size="huge" content="Museums" />
            {error && <ErrorMessage error={error} />}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Segment loading={loading}>
              <Item.Group divided>
                {(museums || []).map((museum) => {
                  return <MuseumsListTile key={museum._id} museum={museum} />;
                })}
              </Item.Group>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ museums, firebase }) => ({
  museums: museums.museums,
  loading: museums.isLoadingMuseumList,
  error: museums.error,
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAll: (page) => dispatch(getMuseumList(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MuseumsList);
