import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Breadcrumb,
  Button,
  Grid,
  Header,
  Image,
  Segment,
  Transition,
} from "semantic-ui-react";

import {
  getAuctionHouse,
  resetAuctionHouseState,
} from "../../../../../../redux/actions/auction_house";
import { Mixpanel } from "../../../../../../utils/Mixpanel";
import placeholder from "../../../../../../images/placeholder.png";
import AuctionHouseAuctionsList from "../AuctionHouseAuctionsList";
import EditAuctionHouseSidePane from "../EditAuctionHouseSidePane";
import UpcomingAuctions from "../UpcomingAuctions";
import { Link, useParams } from "react-router-dom";
import AuthLayout from "../../../../../../layouts/AuthLayout/AuthLayout";
import {
  AUCTION_HOUSE_PATH,
  DASHBOARD_PATH,
} from "../../../../../../constants/paths";

const AuctionHouseDetails = ({ auction_house, fetch, reset, user }) => {
  const { auctionHouseId } = useParams();

  const [error, setError] = useState(false);

  const fetchData = async () => {
    try {
      await fetch(auctionHouseId);
    } catch (e) {
      setError(e);
    }
  };

  useEffect(() => {
    fetchData();
    Mixpanel.page("auction_house", { auction_house: auctionHouseId });
  }, [auctionHouseId]);

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  return (
    <AuthLayout title={auction_house && auction_house.name}>
      <Grid stackable>
        {error && (
          <Grid.Row>
            <Grid.Column width={16}>
              <ErrorMessage error={error} />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row columns={2}>
          <Grid.Column>
            <Segment basic>
              <Breadcrumb>
                <Breadcrumb.Section
                  as={Link}
                  to={DASHBOARD_PATH}
                  content={"Home"}
                />
                <Breadcrumb.Divider />
                <Breadcrumb.Section
                  as={Link}
                  to={AUCTION_HOUSE_PATH}
                  content={"Auction Houses"}
                />
                <Breadcrumb.Divider />
                <Breadcrumb.Section
                  active
                  content={auction_house && auction_house.name}
                />
              </Breadcrumb>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            {auction_house &&
              user &&
              user.roles &&
              user.roles.includes("admin") && (
                <EditAuctionHouseSidePane auctionHouse={auction_house}>
                  <Button
                    basic
                    floated={"right"}
                    icon={"edit"}
                    content={"Edit Auction House"}
                    labelPosition={"left"}
                  />
                </EditAuctionHouseSidePane>
              )}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <Transition.Group
              as={Image}
              animation={"fade left"}
              duration={600}
              visible={auction_house}
              centered
              src={(auction_house && auction_house.image_url) || placeholder}
            />
            <Transition.Group
              basic
              as={Segment}
              animation={"fade up"}
              duration={600}
              visible={auction_house}
            >
              <div style={{ textAlign: "center", marginTop: "1em" }}>
                {auction_house && auction_house.website_url && (
                  <Button
                    basic
                    icon={"globe"}
                    labelPosition={"left"}
                    content={"View Website"}
                    as="a"
                    href={auction_house.website_url}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                )}
                {auction_house && auction_house.email && (
                  <Button
                    basics
                    icon={"mail	"}
                    labelPosition={"left"}
                    content={"Contact Auction House"}
                    as="a"
                    href={auction_house.email}
                    rel="noopener noreferrer"
                    target="_blank"
                  />
                )}
              </div>
            </Transition.Group>
          </Grid.Column>
          <Grid.Column width={8}>
            <Transition.Group
              as={Segment}
              animation={"fade down"}
              duration={600}
              visible={auction_house}
            >
              {auction_house && (
                <Header size="huge" content={auction_house.name} />
              )}
              {auction_house && <p>{auction_house.about}</p>}
            </Transition.Group>
          </Grid.Column>
        </Grid.Row>
        {user && user.roles && user.roles.includes("admin") && (
          <Grid.Row>
            <Grid.Column width={16}>
              <UpcomingAuctions
                auctionHouseId={auctionHouseId}
                filter="auctions_without_dates"
              />
            </Grid.Column>
          </Grid.Row>
        )}
        <Grid.Row>
          <Grid.Column width={16}>
            <UpcomingAuctions
              auctionHouseId={auctionHouseId}
              filter="upcoming"
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <AuctionHouseAuctionsList auctionHouseId={auctionHouseId} />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ firebase, auction_house }) => ({
  user: firebase.profile || null,
  auction_house: auction_house.auctionHouse,
});

const mapDispatchToProps = (dispatch) => ({
  fetch: (id) => dispatch(getAuctionHouse(id)),
  reset: () => dispatch(resetAuctionHouseState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AuctionHouseDetails);
