import React, { useState } from "react";
import PropTypes from "prop-types";
import { Dropdown, Header, Loader, Menu } from "semantic-ui-react";
import { connect, useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";
import { updateCurrentUser } from "../../../redux/actions/me";
import { set } from "lodash";

const ClientSelector = ({ user, update }) => {
  const [loading, setLoading] = useState(false);

  useFirestoreConnect([
    {
      collection: "accounts",
      doc: user.current_account_id,
      storeAs: "account",
    },
  ]);

  const switchUser = async (userID) => {
    setLoading(true);
    try {
      await update({ current_user: userID });
      window.location.reload();
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const accountData = useSelector((state) => state.firestore.ordered.account);
  if (!isLoaded(accountData)) return <Loader />;
  if (isEmpty(accountData)) return null;
  const { users } = accountData[0];
  const keys = Object.keys(users);
  const selectedUser = users[user.current_user_id || user.uid];

  return (
    <Menu.Item>
      <Dropdown text={selectedUser.name} loading={loading}>
        <Dropdown.Menu>
          {(keys || [])
            .filter((key) => key !== user.current_user_id)
            .map((key) => (
              <Dropdown.Item
                key={key}
                content={users[key].name}
                onClick={() => switchUser(key)}
              />
            ))}
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  );
};

ClientSelector.propTypes = {
  user: PropTypes.object,
};

const mapStateToProps = ({ firebase }) => ({
  auth: firebase.auth || null,
  user: firebase.profile || null,
});

const mapDispatchToProps = (dispatch) => ({
  update: (data) => dispatch(updateCurrentUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClientSelector);
