import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
import ArtistsTable from "../ArtistsTable";
import empty_state from "../../../../images/empty_state/artist.png";
import ArtistsGrid from "../ArtistsGrid";
import {
  getArtistList,
  toggleArtistListDisplay,
} from "../../../../redux/actions/artist";
import ErrorMessage from "../../../../components/ErrorMessage";
import { Mixpanel } from "../../../../utils/Mixpanel";
import EmptyState from "../../../../components/EmptyState";
import AuthLayout from "../../../../layouts/AuthLayout/AuthLayout";
import EditArtistSidePane from "../EditArtistSidePane";
import Artist from "../../../../models/artist";

const ArtistsList = ({
  user,
  artists,
  loading,
  error,
  display,
  toggleDisplay,
  getArtists,
  hasMore,
}) => {
  const loadData = async () => {
    if (!hasMore) return;
    await getArtists();
  };

  useEffect(() => {
    Mixpanel.page("artist");
    loadData();
  }, []);

  return (
    <AuthLayout title={"Artists"} bannersEnabled={true}>
      <Grid stackable>
        <Grid.Row verticalAlign={"middle"}>
          <Grid.Column width={12}>
            <Header size="huge" content="Artists" />
          </Grid.Column>
          {user && user.roles && user.roles.includes("admin") && (
            <Grid.Column width={4}>
              <EditArtistSidePane artist={new Artist({})}>
                <Button
                  color="black"
                  floated={"right"}
                  icon={"add"}
                  content={"New Artist"}
                  labelPosition={"left"}
                />
              </EditArtistSidePane>
            </Grid.Column>
          )}
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={14}>
            Found {artists && artists.length} artists
          </Grid.Column>
          <Grid.Column width={2}>
            <Button.Group floated={"right"}>
              <Button
                basic
                icon={"grid layout"}
                active={display === "grid"}
                onClick={() => toggleDisplay("grid")}
              />
              <Button
                basic
                icon={"list"}
                active={display === "table"}
                onClick={() => toggleDisplay("table")}
              />
            </Button.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            {error && <ErrorMessage error={error} />}
            <Segment basic loading={loading} style={{ padding: 0 }}>
              {!loading &&
                artists &&
                artists.length > 0 &&
                display === "table" && <ArtistsTable artists={artists} />}

              {!loading &&
                artists &&
                artists.length > 0 &&
                display === "grid" && <ArtistsGrid artists={artists} />}

              {!loading && (!artists || artists.length === 0) && (
                <EmptyState
                  image={empty_state}
                  title={"No Artists"}
                  detail={"Please document a piece of art to get started"}
                />
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ artists, firebase }) => ({
  user: firebase.profile || null,
  display: artists.display || "table",
  loading: artists.isLoadingArtistList,
  hasMore: artists.hasMoreArtists,
  error: artists.error,
  artists: artists.artists,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDisplay: (name) => dispatch(toggleArtistListDisplay(name)),
  getArtists: () => dispatch(getArtistList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArtistsList);
