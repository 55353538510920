import moment from "moment";
import Artist from "./artist";
import Location from "./location";
import Gallery from "./gallery";
import Image from "./image";
import Tag from "./tag";
import Attachment from "./attachment";
import Appraisal from "./appraisal";

import { editionString, priceString, sizeString } from "../utils/string";

import placeholder from "../images/placeholder.png";

class Piece {
  constructor(data) {
    this._id = data._id || data.objectID || data.id;
    this.title = data.title;
    this.date = data.date;

    this.artist = data.artist && new Artist(data.artist);
    this.artist_name = data.artist_name;

    // Location
    this.location = data.location && new Location(data.location);
    this.location_detail = data.location_detail;
    this.location_history = data.location_history;
    this.location_notes = data.location_notes;

    this.gallery = data.gallery && new Gallery(data.gallery);

    this.new_images = data.new_images || [];
    this.deleted_images = data.deleted_images || [];
    this.images = (data.images || []).map((a) => new Image(a));

    this.category = data.category;
    this.medium = data.medium;
    this.description = data.description;
    this.edition_number = data.edition_number;
    this.edition_size = data.edition_size;

    this.width = data.width;
    this.height = data.height;
    this.depth = data.depth;
    this.measurement_units = data.measurement_units;

    this.is_edition = data.is_edition;
    this.edition_size = data.edition_size;
    this.edition_number = data.edition_number;

    this.is_framed = data.is_framed;
    this.framed_width = data.framed_width;
    this.framed_height = data.framed_height;
    this.framed_depth = data.framed_depth;
    this.framed_measurement_units = data.framed_measurement_units;
    this.frame_details = data.frame_details;
    this.is_signed = data.is_signed;

    this.image_url =
      data.image_url ||
      (data.images && data.images[0] && data.images[0].image_url);
    this.medium_image_url =
      data.medium_image_url ||
      (data.images && data.images[0] && data.images[0].medium_image_url);
    this.small_image_url =
      data.small_image_url ||
      (data.images && data.images[0] && data.images[0].small_image_url);

    this.editionString = editionString(this.edition_number, this.edition_size);
    this.dimensionString = sizeString(
      this.width,
      this.height,
      this.depth,
      this.measurement_units,
    );
    this.framedDimensionString = sizeString(
      this.framed_width,
      this.framed_height,
      this.framed_depth,
      this.framed_measurement_units,
    );
    this.framing_attachments =
      (data.framing_attachments &&
        data.framing_attachments.map((a) => new Attachment(a))) ||
      [];

    this.purchase_price = data.purchase_price;
    this.currency = data.currency;
    this.purchase_notes = data.purchase_notes;

    this.valuation_price = data.valuation_price;
    this.valuation_currency = data.valuation_currency;

    this.purchase_date = data.purchase_date;
    this.purchaseDateString = data.purchase_date
      ? moment(this.purchase_date).format("MMM D YYYY")
      : null;

    this.purchase_attachments =
      (data.purchase_attachments &&
        data.purchase_attachments.map((a) => new Attachment(a))) ||
      [];

    // Provenance
    this.provenance_text = data.provenance_text;
    this.provenance_attachments = (data.provenance_attachments || []).map(
      (a) => new Attachment(a),
    );

    // Exhibited
    this.exhibited_text = data.exhibited_text;
    this.exhibition_attachments = (data.exhibition_attachments || []).map(
      (a) => new Attachment(a),
    );

    // Literature
    this.literature_text = data.literature_text;
    this.literature_attachments = (data.literature_attachments || []).map(
      (a) => new Attachment(a),
    );

    // Insurance
    this.is_insured = data.is_insured;
    this.insurance_notes = data.insurance_notes;
    this.insurance_attachments = (data.insurance_attachments || []).map(
      (a) => new Attachment(a),
    );

    this.condition_attachments = (data.condition_attachments || []).map(
      (a) => new Attachment(a),
    );

    this.condition_details = data.condition_details;
    this.condition_conservation = data.condition_conservation;
    this.condition_conservation_attachments = (
      data.condition_conservation_attachments || []
    ).map((a) => new Attachment(a));
    this.condition_materials = data.condition_materials;
    this.condition_materials_attachments = (
      data.condition_materials_attachments || []
    ).map((a) => new Attachment(a));
    this.condition_manufacturer = data.condition_manufacturer;
    this.condition_manufacturer_attachments = (
      data.condition_manufacturer_attachments || []
    ).map((a) => new Attachment(a));

    this.notes = data.notes;
    this.appraisals = (data.appraisals || []).map((a) => new Appraisal(a));

    this.inventory_identifier = data.inventory_identifier;

    this.status = data.status || "owned";
    this.tags = (data.tags && data.tags.map((a) => new Tag(a))) || [];
  }

  valuationPriceString() {
    return priceString(this.valuation_price, this.valuation_currency);
  }

  purchasePriceString() {
    return priceString(this.purchase_price, this.currency);
  }

  editionString() {
    return editionString(this.edition_number, this.edition_size);
  }

  dimensionString() {
    return sizeString(
      this.width,
      this.height,
      this.depth,
      this.measurement_units,
    );
  }

  framedDimensionString() {
    return sizeString(
      this.framed_width,
      this.framed_height,
      this.framed_depth,
      this.framed_measurement_units,
    );
  }

  toData() {
    return {
      images: this.images && this.images.map((a) => a._id),
      title: this.title || "Untitled",
      medium: this.medium,
      date: this.date,
      category: this.category,
      description: this.description,
      artist: this.artist && this.artist._id,
      gallery: this.gallery && this.gallery._id,
      purchase_price: this.purchase_price,
      purchase_date: this.purchase_date,
      currency: this.currency,
      valuation_price: this.valuation_price,
      valuation_currency: this.valuation_currency,
      location_notes: this.location_notes,
      location_detail: this.location_detail,
      location_history: this.location_history,
      location: this.location && this.location._id,
      width: this.width,
      height: this.height,
      depth: this.depth,
      measurement_units: this.measurement_units,
      is_edition: this.is_edition,
      edition_number: this.edition_number,
      edition_size: this.edition_size,
      is_framed: this.is_framed,
      framed_width: this.framed_width,
      framed_height: this.framed_height,
      framed_depth: this.framed_depth,
      framed_measurement_units: this.framed_measurement_units,
      frame_details: this.frame_details,
      insurance_notes: this.insurance_notes,
      is_insured: this.is_insured,
      purchase_notes: this.purchase_notes,
      provenance_text: this.provenance_text,
      exhibited_text: this.exhibited_text,
      literature_text: this.literature_text,
      condition_details: this.condition_details,
      condition_conservation: this.condition_conservation,
      condition_materials: this.condition_materials,
      appraisals: this.appraisals && this.appraisals.map((a) => a._id),
      tags: this.tags && this.tags.map((a) => a._id).filter((a) => a !== "all"),
      notes: this.notes,
      inventory_identifier: this.inventory_identifier,
      provenance_attachments: this.provenance_attachments.map(
        (attachment) => attachment._id,
      ),
      exhibition_attachments: this.exhibition_attachments.map(
        (attachment) => attachment._id,
      ),
      literature_attachments: this.literature_attachments.map(
        (attachment) => attachment._id,
      ),
      insurance_attachments: this.insurance_attachments.map(
        (attachment) => attachment._id,
      ),
      purchase_attachments: this.purchase_attachments.map(
        (attachment) => attachment._id,
      ),
      framing_attachments: this.framing_attachments.map(
        (attachment) => attachment._id,
      ),
      condition_conservation_attachments:
        this.condition_conservation_attachments.map(
          (attachment) => attachment._id,
        ),
      condition_manufacturer_attachments:
        this.condition_manufacturer_attachments.map(
          (attachment) => attachment._id,
        ),
      condition_materials_attachments: this.condition_materials_attachments.map(
        (attachment) => attachment._id,
      ),
      condition_attachments: this.condition_attachments.map(
        (attachment) => attachment._id,
      ),
      status: this.status,
      is_signed: this.is_signed,
    };
  }

  imageUrl() {
    if (this.images && this.images[0]) {
      const image = this.images[0];
      return image.image_url;
    }
    return placeholder;
  }

  smallImageUrl() {
    if (this.images && this.images[0]) {
      const image = this.images[0];
      return image.small_image_url || image.image_url;
    }
    return placeholder;
  }

  mediumImageUrl() {
    if (this.images && this.images[0]) {
      const image = this.images[0];
      return image.medium_image_url || image.image_url;
    }
    return placeholder;
  }
}

export default Piece;
