import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { Button, Grid, Header, Segment } from "semantic-ui-react";
import LocationsTable from "../LocationsTable";
import empty_state from "../../../../images/empty_state/location.png";
import LocationsGrid from "../LocationsGrid";
import {
  toggleLocationListDisplay,
  setOrderBy,
} from "../../../../redux/actions/location";
import ErrorMessage from "../../../../components/ErrorMessage";
import { Mixpanel } from "../../../../utils/Mixpanel";
import EmptyState from "../../../../components/EmptyState";
import AuthLayout from "../../../../layouts/AuthLayout/AuthLayout";
import EditLocationSidePane from "../EditLocationSidePane";
import { useFirestoreConnect, isLoaded } from "react-redux-firebase";
import Location from "../../../../models/location";

const LocationsList = ({
  auth,
  user,
  display,
  toggleDisplay,
  changeOrderBy,
  orderBy,
}) => {
  useFirestoreConnect([
    {
      orderBy: orderBy,
      collection: "locations",
      doc: (user && user.current_user_id) || auth.uid,
      subcollections: [{ collection: "locations" }],
      storeAs: "locations",
    },
  ]);
  const locations = useSelector((state) => state.firestore.ordered.locations);
  const error = useSelector(
    (state) => state.firestore.errors.byQuery.locations,
  );

  useEffect(() => {
    Mixpanel.page("location");
  }, []);

  return (
    <AuthLayout title={"Locations"} bannersEnabled={true}>
      <Grid stackable>
        <Grid.Row verticalAlign={"middle"}>
          <Grid.Column width={12}>
            <Header size={"huge"} content={"Locations"} />
          </Grid.Column>

          <Grid.Column width={4}>
            <EditLocationSidePane location={{}}>
              <Button
                color={"black"}
                icon="plus"
                content={"New Location"}
                labelPosition={"left"}
                floated={"right"}
              />
            </EditLocationSidePane>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={14}>
            Found {locations && locations.length} locations
          </Grid.Column>
          <Grid.Column width={2}>
            <Button.Group floated={"right"}>
              <Button
                basic
                icon={"grid layout"}
                active={display === "grid"}
                onClick={() => toggleDisplay("grid")}
              />
              <Button
                basic
                icon={"list"}
                active={display === "table"}
                onClick={() => toggleDisplay("table")}
              />
            </Button.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            {error && <ErrorMessage error={error} />}
            <Segment basic loading={!isLoaded(locations)}>
              {locations && locations.length > 0 && display === "table" && (
                <LocationsTable
                  orderBy={orderBy}
                  handleOrderByChange={(key) => changeOrderBy(key)}
                  locations={locations.map((data) => new Location(data))}
                />
              )}

              {locations && locations.length > 0 && display === "grid" && (
                <LocationsGrid
                  locations={locations.map((data) => new Location(data))}
                />
              )}

              {(!locations || locations.length === 0) && (
                <EmptyState
                  image={empty_state}
                  title={"No Locations"}
                  detail={"Please create a location to get started"}
                />
              )}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );
};

const mapStateToProps = ({ locations, firebase }) => ({
  auth: firebase.auth || null,
  user: firebase.profile || null,
  orderBy: locations.orderBy,
  display: locations.display,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDisplay: (name) => dispatch(toggleLocationListDisplay(name)),
  changeOrderBy: (data) => dispatch(setOrderBy(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationsList);
