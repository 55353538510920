import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Segment } from "semantic-ui-react";
import { usePushNotifications } from "../../hooks/usePushNotifications";
import { useFirebase } from "../../hooks/useFirebase";
import Banners from "../../containers/Banners/Banners";

const AuthLayout = ({ title, description, children, bannersEnabled }) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({ user: state.firebase.profile }));
  const { isSupported: isPushSupported, register: registerForPushNotifications } = usePushNotifications(user);

  useFirebase();

  useEffect(() => {
    registerForPushNotifications();
  }, [dispatch, registerForPushNotifications]);

  useEffect(() => {
    if (user && window && window.drift) {
      window.drift.identify(user._id, {
        email: user.email,
        name: user.name,
        account_level: user.account_level,
      });
    }
  }, [user]);

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title || "ArtCollection.io"}</title>
        {description && <meta name="description" content={description} />}
      </Helmet>
      <Segment basic style={{ paddingTop: "5em" }}>
        {bannersEnabled && <Banners bannersEnabled />}
        {children}
      </Segment>
    </>
  );
};

AuthLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  bannersEnabled: PropTypes.bool,
};

export default AuthLayout;
