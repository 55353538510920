import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Grid, Header, Segment, Form, Select } from "semantic-ui-react";
import AuctionRecordsTable from "../AuctionRecordsTable";
import AuctionRecordsGrid from "../AuctionRecordsGrid";
import { Mixpanel } from "../../../../utils/Mixpanel";
import AuthLayout from "../../../../layouts/AuthLayout/AuthLayout";
import { searchAuctionResults, toggleAuctionRecordListDisplay } from "../../../../redux/actions/auction_result";
import SelectArtist from "../../../../components/Modals/Artist/Select";
import InfiniteScroll from "react-infinite-scroller";

const AuctionRecordsList = ({ display, toggleDisplay, user, currencies, search, auctionRecords, hasMore, isLoading }) => {
  const [artist, setArtist] = useState(null);
  const [lotFilter, setLotFilter] = useState("sold");
  const [lotPerformance, setLotPerformance] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    Mixpanel.page("auction_record");
  }, []);

  useEffect(() => {
    setPage(1);
    fetchAuctionRecords(1);
  }, [artist, lotFilter]);

  const fetchAuctionRecords = (page) => {
    let query = {
      lot_filter: lotFilter
    };
    if (artist) {
      query.artist_id = artist._id;
    }
    if (lotPerformance) {
      query.lot_performance = lotPerformance;
    }
    search(page, 25, query);
  }

  return (
    <AuthLayout title={"Auction Records"}>
      <Grid stackable>
        <Grid.Row verticalAlign={"middle"}>
          <Grid.Column width={10}>
            <Header size="huge" content={"Auction Records"} />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row
        >
          <Grid.Column width={3}>
            <Segment>
              <Form>
                <Form.Field>
                  <label>Artist</label>
                  <SelectArtist
                    artist={artist}
                    onSelectArtist={(value) => setArtist(value)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Lot Filter</label>
                  <Select
                    options={[
                      { key: "upcoming", value: "upcoming", text: "Upcoming" },
                      { key: "sold", value: "sold", text: "Sold" },
                      { key: "unsold", value: "unsold", text: "Unsold" },
                    ]}
                    value={lotFilter}
                    onChange={(e, { value }) => setLotFilter(value)}
                  />
                </Form.Field>
                {
                  lotFilter === "sold" && 
                <Form.Field>
                  <label>Lot Performance</label>
                  <Select
                    options={[
                      { key: "above", value: "above", text: "Above Estimate" },
                      { key: "below", value: "below", text: "Below Estimate" },
                      { key: "within", value: "within", text: "Within Estimate" },
                      { key: "unsold", value: "unsold", text: "Unsold"}
                    ]}
                    value={lotPerformance}
                    onChange={(e, { value }) => setLotPerformance(value)}
                  />
                </Form.Field>
                }
              </Form>
            </Segment>
          </Grid.Column>
          <Grid.Column width={13}>
            <Button.Group floated={"right"}>
              <Button
                basic
                icon={"grid layout"}
                active={display === "grid"}
                onClick={() => toggleDisplay("grid")}
              />
              <Button
                basic
                icon={"list"}
                active={display === "table"}
                onClick={() => toggleDisplay("table")}
              />
            </Button.Group>
            <InfiniteScroll
              initialLoad={true}
              loadMore={(page) => fetchAuctionRecords(page)}
              hasMore={hasMore && !isLoading}
              loader={
                <Header
                  key={"loader"}
                  textAlign={"center"}
                  size={"small"}
                  content={"Loading ..."}
                />
              }
            >
            {display === "table" && <AuctionRecordsTable auctionRecords={auctionRecords} user={user} currencies={currencies} />}
            {display === "grid" && <AuctionRecordsGrid auctionRecords={auctionRecords} user={user} currencies={currencies} />}
            {hasMore && <Header size={"small"} content={"Scroll down to load more"} /> }
            </InfiniteScroll>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </AuthLayout>
  );x
};

const mapStateToProps = ({ auctionRecords, firebase, currencies }) => ({
  user: firebase.profile || null,
  currencies: currencies.currencies,
  display: auctionRecords.display,
  error: auctionRecords.error,
  auctionRecords: auctionRecords.auctionRecords,
  hasMore: auctionRecords.hasMoreAuctionRecords,
  isLoading: auctionRecords.isLoadingAuctionRecordList,
});

const mapDispatchToProps = (dispatch) => ({
  toggleDisplay: (name) => dispatch(toggleAuctionRecordListDisplay(name)),
  search: (page, limit, query) => dispatch(searchAuctionResults(page, limit, query)  )
});

export default connect(mapStateToProps, mapDispatchToProps)(AuctionRecordsList);
